import { InputTypes } from '@eagle/common';
import { Thing } from '@eagle/core-data-types';
import {
  EventDialogProvider,
  EventFields,
  EventHistory,
  EventHistoryAlert,
  EventHistoryMap,
  EventLocationData,
  FilterField,
  HistoryDrawerController,
  HistorySearchProvider,
  HISTORY_MAP_CENTER,
  ListSearchProvider,
  MapStorageKeys,
  Maybe,
  MobileTabSelection,
  useTitle
} from '@eagle/react-common';
import { FC, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { EventHistoryNavigator } from './event-history-navigator';

export const EventHistoryController: FC<{ thingId?: Thing }> = () => {
  const { t } = useTranslation(['common', 'track']);
  useTitle(t('common:common.labels.history'));
  const [filterOpen, setFilterOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [hoveredEventId, setHoveredEventId] = useState<Maybe<string>>(null);
  const [selectedEvent, setSelectedEvent] = useState<Maybe<EventLocationData>>(null);

  const keyDownHandler = useCallback((event: KeyboardEvent): void => {
    if (event.key === 'F' && event.shiftKey && event.altKey) setFilterOpen(!filterOpen);
  }, [filterOpen]);

  const fields: FilterField[] = [
    {
      definition: {
        description: null,
        format: 'raw',
        input: InputTypes.CHECK,
        label: t('track:page.history.event-category.label'),
        multiple: null,
        type: 'entity',
      },
      path: 'eventTypeId',
      values: [
        { id: EventFields.DRIVER_BEHAVIOR, display: t('common:features.driver-behavior') },
        { id: EventFields.MEDIA, display: t('track:page.history.labels.media') },
        { id: EventFields.SAFETY_INCIDENT, display: t('common:component.filter-dropdown.labels.safety-incidents') },
        { id: EventFields.TRACKING, display: t('common:component.filter-dropdown.labels.tracking') },
        { id: EventFields.OTHER, display: t('common:component.filter-dropdown.labels.other') },
      ],
    },
  ];

  const renderHistoryContent = (mobileTabSelection: MobileTabSelection): JSX.Element => (
    <HistoryDrawerController
      fields={fields}
      filterDrawerOpen={filterOpen}
      handleDrawerToggle={setIsDrawerOpen}
      handleFilterOpen={setFilterOpen}
      hoveredEventId={hoveredEventId}
      isDrawerOpen={isDrawerOpen}
      isTransitioning={isTransitioning}
      mobileTabSelection={mobileTabSelection}
      selectedEvent={selectedEvent}
      setHoveredEventId={setHoveredEventId}
      setSelectedEvent={setSelectedEvent}
      storageKey="history-page"
    />
  );

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [keyDownHandler]);

  return (
    <HistorySearchProvider>
      <EventDialogProvider setSelectedEvent={setSelectedEvent}>
        <ListSearchProvider dataKey="event-history">
          <EventHistoryNavigator />
          <EventHistory
            isDrawerOpen={isDrawerOpen}
            renderBottomDrawerContent={() => <></>}
            renderHistoryContent={renderHistoryContent}
            renderTopDrawerContent={() => (
              <EventHistoryMap
                center={HISTORY_MAP_CENTER}
                hoveredEventId={hoveredEventId}
                selectedEvent={selectedEvent}
                setHoveredEventId={setHoveredEventId}
                setSelectedEvent={setSelectedEvent}
                storageKey={MapStorageKeys.HISTORY_MAP_SAVED_POSITION}
              >
                <EventHistoryAlert
                  message={<Trans components={[<i key="key-1" />]} i18nKey="track:page.history.no-history-message.labels" />}
                  sx={{ p: 2, position: 'absolute', width: '100%', zIndex: 1300 }}
                  title={t('track:page.history.no-history.labels')}
                />
              </EventHistoryMap>
            )}
            setIsTransitioning={setIsTransitioning}
          />
        </ListSearchProvider>
      </EventDialogProvider>
    </HistorySearchProvider>
  );
};
