/* eslint-disable react-hooks/exhaustive-deps */
import { InputTypes } from '@eagle/common';
import { AppliedFilter, EntityField, EntityItemFields, EventFields, FilterFieldTypes, useHistorySearch, useListSearch } from '@eagle/react-common';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Location as ReactRouterLocation, useLocation } from 'react-router-dom';
import { ulid } from 'ulid';

export interface LocationState extends ReactRouterLocation {
  dateEnd: Date;
  dateStart: Date;
  entityId: string;
  entityItemFields: EntityItemFields;
  expanded: boolean;
  filters: string[];
  focused: boolean;
}

export const EventHistoryNavigator: FC = () => {
  const { state } = useLocation() as { state: LocationState };
  const { addEntityWithDateTime, entityItems } = useHistorySearch();
  const { setFilters } = useListSearch();
  const { t } = useTranslation(['common', 'track']);

  const createAppliedFilter = (
    value: EntityField,
  ): AppliedFilter => {
    return {
      id: ulid(),
      definition: {
        description: null,
        format: 'raw',
        input: InputTypes.CHECK,
        label: t('track:page.history.event-category.label'),
        multiple: null,
        type: 'entity' as FilterFieldTypes,
      },
      propertyPath: 'eventTypeId',
      value,
    };
  };

  useEffect(() => {
    if (!state) return;
    const { dateEnd, dateStart, entityId, entityItemFields, expanded, filters, focused } = state;
    if (!entityId || !entityItemFields || !dateStart || !dateEnd) return;
    if (entityItems.find((item) => item.id === entityId)) return;
    addEntityWithDateTime(
      entityId,
      entityItemFields,
      dateStart,
      dateEnd,
      focused,
      expanded,
    );

    const appliedFilters = filters.map((item) => {
      return {
        display: t(`common:features.${item}`),
        id: item,
      };
    });

    if (appliedFilters.length === Object.entries(EventFields).length) return;

    setFilters(appliedFilters.map((v) => createAppliedFilter(v)));
  }, [state]);

  return <></>;
};
